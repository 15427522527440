<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">แก้ไขหัวข้อเรื่อง</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            v-if="title"
            @submit="onSubmitTitle"
            :validation-schema="titleValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
                        <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อใหญ่</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="mode"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    as="select"
                     v-model="title.mode"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="ด้านความปลอดภัยทางชีวิตและทรัพย์สิน">ด้านความปลอดภัยทางชีวิตและทรัพย์สิน</option>
                    <option value="ด้านสาธารณูปโภค">ด้านสาธารณูปโภค</option>
                    <option value="ด้านสาธารณสุขและสิ่งแวดล้อม">ด้านสาธารณสุขและสิ่งแวดล้อม</option>
                    <option value="เหตุรำคาน และอื่นๆ">เหตุรำคาน และอื่นๆ</option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="mode" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อเรื่อง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="id" v-model="title.id" />
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="หัวข้อเรื่อง"
                    v-model="title.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >พารามิเตอร์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    v-model="title.parameter"
                    type="text"
                    name="parameter"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="พารามิเตอร์"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="parameter" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >Token</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="token"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Token"
                    v-model="title.token"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="token" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รูป</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="d-flex justify-center align-center">
                    <div style="width: 256px; height: 256px; padding: 0px 3px">
                      <Field type="hidden" name="icon" v-model="imageLogo" />
                      <div v-if="imageLogo" style="position: relative">
                        <img class="image-logo" :src="imageLogo" alt="" />
                        <button
                          @click="deleteImage"
                          class="btn btn-danger bt-delete"
                        >
                          x
                        </button>
                      </div>
                      <button
                        v-else
                        type="button"
                        class="
                          form-control
                          btn btn-outline-secondary btn-new-icon
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#modal_new_icon"
                      >
                        <img
                          src="@/assets/picture.png"
                          class="icon-ima"
                        /><br />
                        แนบรูป
                      </button>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
<CropImage :aspectRatio="1/1" :height="256" :width="256" @clicked="satImageLogo"></CropImage>
</template>

<script>
import CropImage from "@/components/modals/CropImage.vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useTitles from "@/core/services/api/appeal/titles";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "appeal-title-create",
  components: {
    ErrorMessage,
    Field,
    Form,
    CropImage,
  },
  props: ["id"],
  setup(props) {
    const router = useRouter();

    const { title, getTitle, updateTitle } = useTitles();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const imageLogo = ref("");
    const titleValidator = Yup.object().shape({
      mode: Yup.string()
        .required("กรุณาเลือก หัวข้อใหญ่")
        .label("หัวข้อใหญ่"),
      name: Yup.string()
        .required("กรุณากรอก หัวข้อเรื่อง")
        .label("หัวข้อเรื่อง"),
      token: Yup.string().required("กรุณากรอก Token").label("Token"),
      icon: Yup.string().required("กรุณาเลือก รูปภาพ").label("รูปภาพ"),
    });

    const onSubmitTitle = (values) => {
      Sconfirm("ยืนยัน การแก้ไขหัวข้อเรื่อง", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            await updateTitle(values)
              .then(() => {
                SToast("success", "แก้ไข หัวข้อเรื่อง สำเร็จ");
                submitButton.value?.removeAttribute("data-kt-indicator");
              })
              .catch((error) => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.name) {
                    SToast("error", `${errors.name[0]}`);
                  }
                  if (errors.parameter) {
                    SToast("error", `${errors.parameter[0]}`);
                  }
                  if (errors.name && errors.parameter) {
                    SToast(
                      "error",
                      `${errors.name[0]} ,<br> ${errors.parameter[0]}`
                    );
                  }
                }
              });
          }
        }
      );
    };
    watch(title, (title) => {
      imageLogo.value = title.icon;
    });
    function satImageLogo(image) {
      imageLogo.value = image;
    }

    function deleteImage() {
      imageLogo.value = "";
    }

    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.appeals.titles.index" });
      }
      getTitle(props.id);
      checkPage("title-edit");
      setCurrentPageBreadcrumbs("แก้ไขหัวข้อเรื่อง", ["จัดการหัวข้อเรื่อง"]);
    });

    return {
      titleValidator,
      title,
      imageLogo,
      submitButton,
      onSubmitTitle,
      satImageLogo,
      deleteImage,
    };
  },
});
</script>
<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
</style>
